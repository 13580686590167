<template>
<div  class="fadeInRight charge_log ">
		<div class="title-box clearfix">
			 <span class="m-title">充值日志详情</span>
			<el-form :inline="true" :model="formInline" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="来源:">
					<el-select v-model="formInline.source" placeholder="全部">
						<el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="类型:">
					<el-select v-model="formInline.type" placeholder="全部">
						<el-option v-for="(item,index) in typeOptions" :key="index" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="卡号：">
					<el-input v-model="formInline.pageSearchKeys" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="订单：">
					<el-input v-model="formInline.orderNumber" placeholder=""></el-input>
				</el-form-item>

				<el-form-item label="操作时间:">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					 format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="searchData" icon="el-icon-search" class="themed-button">搜索查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="exportExcel" class="themed-button" icon="el-icon-caret-left">导出excel</el-button>
				</el-form-item>
				<iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;" src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe  style="width: 100%;" ref="table" :max-height="maxTableHeight" border>
				<el-table-column prop="agentName" label="代理商名称">
				</el-table-column>
				<el-table-column prop="orderNumber" label="订单号">
				</el-table-column>
				<el-table-column prop="iccidMark" label="卡号">
				</el-table-column>
				<el-table-column prop="mealName" label="套餐名">
				</el-table-column>
				<el-table-column prop="mealType" label="套餐类型">
				</el-table-column>
				<el-table-column label="来源">
					<template slot-scope="scope">
						{{getSource(scope.row)}}
					</template>
				</el-table-column>
				<el-table-column label="类型">
					<template slot-scope="scope1">
						{{getType(scope1.row)}}
					</template>
				</el-table-column>
				<el-table-column label="支付状态">
					<template slot-scope="scope2">
						{{getPayStatus(scope2.row)}}
					</template>
				</el-table-column>
				<el-table-column prop="oldMoney" label="原本金额">
				</el-table-column>
				<el-table-column prop="newMoney" label="操作金额">
				</el-table-column>
				<el-table-column prop="money" label="现在金额">
				</el-table-column>
				<el-table-column prop="descriptionLog" label="修改描述">
				</el-table-column>
				<el-table-column prop="operator" label="操作人">
				</el-table-column>
				<el-table-column prop="createDatetime" label="操作时间">
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		
	</div>
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {
		chargeLogDetailList,
    agentrechargelogExport
	} from "@/api/agentManagement.js"
	export default {
		name:'chargeLogDetail',
		mixins: [onresize],
		data() {
			return {
				options: [{
						value: "",
						label: "全部"
					},
					{
						value: "1",
						label: "充值"
					},
					{
						value: "2",
						label: "下单"
					},
					{
						value: "3",
						label: "收益转入"
					},
					{
						value: "4",
						label: "公众号下单"
					},
					{
						value: "5",
						label: "接口下单"
					},
					{
						value: "6",
						label: "减扣"
					},
					{
						value: "7",
						label: "代理商微信预充值"
					},
				],
				typeOptions: [{
						value: "",
						label: "全部"
					},
					{
						value: "1",
						label: "支出"
					},
					{
						value: "2",
						label: "收入"
					},
				],
				value1: "",
				formInline: {
					agentId: this.$route.query.id,
					source: "",
					type: "",
					pageSearchKeys: "",
					orderNumber: "",
					startTime: "",
					endTime:"",
				},
				tabledata: [],
				page: 1,
				pageSize: 15,
				total: 0,



			}
		},
		created() {
     /* startTime: this.value1 == null ? "" : this.value1[0],
      endTime: this.value1 == null ? "" : this.value1[1], */
			/* this.formInline.startTime = this.value1 == null ? "" : this.value1[0]
			this.formInline.endTime = this.value1 == null ? "" : this.value1[1] */
			
			this.getChargeLogDetailList(this.formInline)
		},
		watch:{
			value1(value){
				this.formInline.startTime = value == null ? "" : value[0]
				this.formInline.endTime = value == null ? "" : value[1]
			}
		},
		methods: {
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.formInline)
				this.getChargeLogDetailList(params)

			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.formInline)
				this.getChargeLogDetailList(params)
			},
			searchData() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
				}
				Object.assign(params, this.formInline)
				console.log(this.value1)
				console.log(this.value1 == null )
				console.log(this.value1 == null ? "" : this.value1[0])
				console.log(this.formInline.startTime)
				console.log(this.formInline)
				this.getChargeLogDetailList(params)
			},
			/* exportExcel() {
				let agentId = this.formInline.agentId == undefined ? "" : this.formInline.agentId
				let source = this.formInline.source == undefined ? "" : this.formInline.source
				let type = this.formInline.type == undefined ? "" : this.formInline.type
				let pageSearchKeys = this.formInline.pageSearchKeys == undefined ? "" : this.formInline.pageSearchKeys
				let orderNumber = this.formInline.orderNumber == undefined ? "" : this.formInline.orderNumber
				let startTime = this.formInline.startTime == undefined ? "" : this.formInline.startTime
				let endTime = this.formInline.endTime == undefined ? "" : this.formInline.endTime
				let url = "https://iottest.sunshineiot.net/traffic-web/agent/agentrechargelog/export2excel.do?" + "agentId=" +
					agentId + "&source=" + source + "&type=" + type + "&pageSearchKeys=" + pageSearchKeys + "&orderNumber=" +
					orderNumber + "&startTime=" + startTime + "&endTime=" + endTime;
				url = encodeURI(encodeURI(url));
				document.getElementById("hidden_down_iframe").src = url;
			}, */
			exportExcel(){
							loading.show()
							let params = {
								agentId:this.formInline.agentId == undefined ? "" : this.formInline.agentId,
								source:this.formInline.source == undefined ? "" : this.formInline.source,
								type:this.formInline.type == undefined ? "" : this.formInline.type,
								pageSearchKeys:this.formInline.pageSearchKeys == undefined ? "" : this.formInline.pageSearchKeys,
								orderNumber:this.formInline.orderNumber == undefined ? "" : this.formInline.orderNumber,
								startTime:this.formInline.startTime == undefined ? "" : this.formInline.startTime,
								endTime: this.formInline.endTime == undefined ? "" : this.formInline.endTime,
							}
							agentrechargelogExport(params).then(res=>{
								if (res.system_result_key == '0') {
									this.$alert('导出成功，请到导出记录中下载', '提示', {
									    confirmButtonText:'确定',
									}).then(()=>{
										this.$router.push('/tob/i_export_list')
									});
								}else{
									this.$message({
									    showClose: true,
									    message: res.system_result_message_key,
									    type: 'error'
									});
								}
							})
						},
			getChargeLogDetailList(params) {
				chargeLogDetailList(params).then(res => {
					//console.log(res)
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			getSource(rowData) {
				if (rowData.source == 1) return "充值";
				else if (rowData.source == 2) return "下单";
				else if (rowData.source == 3) return "收益转入";
				else if (rowData.source == 4) return "微信下单";
				else if (rowData.source == 5) return "api下单";
				else if (rowData.source == 6) return "减扣";
				else if (rowData.source == 7) return "代理商微信预充值";
			},
			getType(rowData) {
				if (rowData.type == 1) return "支出";
				else if (rowData.type == 2) return "收入";
			},
			getPayStatus(rowData) {
				if (rowData.source == 2 || rowData.source == 4 || rowData.source == 5) {
					if (rowData.payStatusInt == 1) return "已支付";
					else return "已退款";
				} else return "已支付";
			},

		},

	}
</script>

<style>
	.charge_log  .el-form-item__label {vertical-align: top;}
</style>
